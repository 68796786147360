@import 'base.sass';

html {
    body {
        footer {
            position: relative;
            overflow: hidden;
            background-image: url('../../images/fondo.jpg');
            background-position: center center;
            svg {
                max-width: 120%;
                min-width: 40%;
                position: absolute;
                z-index: 1;
                left: -100px;
                bottom: 100px;
                @media (min-width: $breakpoint1) {
                    bottom: -100px; }
                path {
                    fill: $color3; } }
            .cuerpo {
                position: relative;
                z-index: 5;
                width: 100%;
                max-width: $cuerpo2;
                margin: 80px auto 0;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                @media (min-width: $breakpoint1) {
                    justify-content: center; }
                .columna {
                    width: 50%;
                    padding: 0 30px;
                    @media (min-width: $breakpoint1) {
                        padding: 30px; }
                    ul {
                        list-style: none;
                        li {
                            padding: 10px 0px;
                            a {
                                color: $color3;
                                text-decoration: none;
                                font-size: rem(18);
                                line-height: rem(18.26);
                                font-weight: bold; } } }
                    @media (min-width: $breakpoint1) {
                        width: 25%;
                        min-width: 250px; }
                    .wpcf7 {
                        color: $color5;
                        a {
                            color: $color5; }
                        .titulo {
                            color: $color3;
                            font-family: Harman;
                            font-size: rem(40);
                            line-height: rem(40); }
                        .subtitulo {
                            color: $color3;
                            font-size: rem(18);
                            line-height: rem(22); }
                        .input {
                            border-radius: 5px;
                            margin: 15px auto;
                            input.email {
                                display: inline-block;
                                width: calc(100% - 50px);
                                height: 50px;
                                padding: 15px;
                                background: $color1;
                                border: none;
                                font-size: rem(14);
                                line-height: rem(17); }
                            .boton {
                                display: inline-block;
                                width: 50px;
                                height: 50px;
                                background: $color7;
                                background-image: url('../../images/flechas.svg');
                                background-repeat: no-repeat;
                                background-size: 50%;
                                background-position: center;
                                border: none;
                                color: $color1; } }
                        .wpcf7-acceptance {
                            .wpcf7-list-item-label {
                                font-size: rem(13px); } }
                        .wpcf7-spinner {
                            display: none; }
                        .submitting {
                            .wpcf7-spinner {
                                display: block; } } } }
                .columna_1 {
                    order: 1; }
                .columna_2 {
                    order: 2; }
                .columna_3 {
                    order: 3; }
                .columna_4 {
                    order: 5;
                    width: 100%;
                    @media (min-width: $breakpoint1) {
                        order: 4;
                        width: 25%; } }
                #rrss {
                    width: 100%;
                    padding: 36px;
                    padding-bottom: 56px;
                    order: 4;
                    @media (min-width: $breakpoint1) {
                        order: 5;
                        padding: 48px;
                        padding-bottom: 57px; } }
                .rrss {
                    width: 100%;
                    max-width: $cuerpo4;
                    margin: 0 auto; } }
            #sub_footer {
                padding: 10px;
                padding-bottom: 80px;
                background: $color3;
                @media (min-width: $breakpoint1) {
                    padding-bottom: 15px; }
                .cuerpo {
                    margin: 0 auto;
                    display: flex;
                    flex-direction: column;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    justify-content: center;
                    align-items: center;
                    @media (min-width: $breakpoint1) {
                        flex-direction: row; }
                    #menu_lopd {
                        ul {
                            list-style: none;
                            display: flex;
                            flex-wrap: wrap;
                            flex-direction: column;
                            text-align: center;
                            @media (min-width: $breakpoint1) {
                                flex-direction: row;
                                text-align: left; }
                            li {
                                margin: 10px 15px 10px 0px;
                                a {
                                    text-decoration: none;
                                    color: $color1;
                                    font-size: rem(16);
                                    line-height: rem(19); } } } }
                    #firma {
                        margin: 10px;
                        color: $color1;
                        font-size: rem(12);
                        line-height: rem(14.53);
                        img {
                            margin: 0 5px;
                            width: auto;
                            height: 15px; }
                        .bebrand {
                            height: 20px;
                            margin-bottom: -5px; } } } } } } }
