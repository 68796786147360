$color1: var(--color1);
$color2: var(--color2);
$color3: var(--color3);
$color4: var(--color4);
$color5: var(--color5);
$color6: var(--color6);
$color7: var(--color7);
$color8: var(--color8);
$color9: var(--color9);
$color10: var(--color10);

$breakpoint0: 600px;
$breakpoint1: 770px;
$breakpoint2: 1000px;

$cuerpo1: 1450px;
$cuerpo2: 1210px;
$cuerpo3: 900px;
$cuerpo4: 500px;

@function rem($value) {
    @return ($value / 18) + rem; }

@mixin boton {
    display: block;
    width: max-content;
    margin: 0 auto;
    padding: 14px 25px;
    box-shadow: 0px 2px 30px rgba(252, 97, 97, 0.88);
    border-radius: 50px;
    background: $color4;
    color: $color1;
    text-decoration: none;
    text-transform: uppercase;
    font-family: DIN;
    font-size: rem(18);
    font-weight: bold;
    line-height: rem(25.2);
    transition: all 300ms ease-in-out;
    &:hover {
        box-shadow: 0px 0px 0px rgba(252, 97, 97, 0); }
    &:after {
        content: url('../../images/flecha-blanca-derecha.svg');
        margin-left: 10px; } }

@mixin hamburguesa {
    display: block;
    width: 30px;
    height: 5px;
    background: $color2;
    border-radius: 5px; }

@mixin ocultascroll {
    overflow-x: scroll;
    cursor: grab;
    &:active {
        cursor: grabbing; }
    user-select: none;
    -webkit-user-select: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
    &:-webkit-scrollbar {
        display: none; } }
